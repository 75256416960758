import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

export default () => {
  return (
    <StyledContainer>
      <div className="column-container">
        <div className="column column-1">
          <div className="rows">
            <div className="row">
              <h1>Page not found</h1>
              <p>
                The page you're looking for cannot be found.
                <br />
                Please go back to the <StyledLink to="/">homepage</StyledLink>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  overflow: auto;
  padding: 0 30px;

  @media (min-width: 992px) {
    padding: 110px 15% 0;
  }

  h1,
  a {
    color: #135193;
  }

  h1 {
    font-size: 40px;
    color: #135193;
  }
`

const StyledLink = styled(Link)`
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`
